import React from "react"
import styled from "astroturf"

export const IconDevelopment = () => {
  return <Svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 12.5V43.75C7.50198 45.407 8.1611 46.9956 9.33277 48.1672C10.5044 49.3389 12.093 49.998 13.75 50H73.75C75.407 49.998 76.9956 49.3389 78.1672 48.1672C79.3389 46.9956 79.998 45.407 80 43.75V12.5H7.5Z"
      fill="#9DB8E6"/>
    <path
      d="M73.75 0H13.75C12.093 0.00198482 10.5044 0.661102 9.33277 1.83277C8.1611 3.00445 7.50198 4.59301 7.5 6.25V10H80V6.25C79.998 4.59301 79.3389 3.00445 78.1672 1.83277C76.9956 0.661102 75.407 0.00198482 73.75 0V0ZM13.75 7.01625C13.5028 7.01625 13.2611 6.94294 13.0555 6.80559C12.85 6.66823 12.6898 6.47301 12.5952 6.2446C12.5005 6.0162 12.4758 5.76486 12.524 5.52239C12.5722 5.27991 12.6913 5.05718 12.8661 4.88237C13.0409 4.70755 13.2637 4.5885 13.5061 4.54027C13.7486 4.49204 13.9999 4.51679 14.2284 4.6114C14.4568 4.70601 14.652 4.86623 14.7893 5.07179C14.9267 5.27735 15 5.51902 15 5.76625C15 6.09777 14.8683 6.41571 14.6339 6.65013C14.3995 6.88455 14.0815 7.01625 13.75 7.01625ZM18.75 7.01625C18.5028 7.01625 18.2611 6.94294 18.0555 6.80559C17.85 6.66823 17.6898 6.47301 17.5951 6.2446C17.5005 6.0162 17.4758 5.76486 17.524 5.52239C17.5722 5.27991 17.6913 5.05718 17.8661 4.88237C18.0409 4.70755 18.2637 4.5885 18.5061 4.54027C18.7486 4.49204 18.9999 4.51679 19.2284 4.6114C19.4568 4.70601 19.652 4.86623 19.7893 5.07179C19.9267 5.27735 20 5.51902 20 5.76625C20 6.09777 19.8683 6.41571 19.6339 6.65013C19.3995 6.88455 19.0815 7.01625 18.75 7.01625ZM23.75 7.01625C23.5028 7.01625 23.2611 6.94294 23.0555 6.80559C22.85 6.66823 22.6898 6.47301 22.5951 6.2446C22.5005 6.0162 22.4758 5.76486 22.524 5.52239C22.5722 5.27991 22.6913 5.05718 22.8661 4.88237C23.0409 4.70755 23.2637 4.5885 23.5061 4.54027C23.7486 4.49204 23.9999 4.51679 24.2284 4.6114C24.4568 4.70601 24.652 4.86623 24.7893 5.07179C24.9267 5.27735 25 5.51902 25 5.76625C25 6.09777 24.8683 6.41571 24.6339 6.65013C24.3995 6.88455 24.0815 7.01625 23.75 7.01625Z"
      fill="#9DB8E6"/>
    <path
      d="M66.25 17.8125H6.25C4.59301 17.8145 3.00445 18.4736 1.83277 19.6453C0.661102 20.8169 0.00198482 22.4055 0 24.0625L0 66.25C0.00198482 67.907 0.661102 69.4956 1.83277 70.6672C3.00445 71.8389 4.59301 72.498 6.25 72.5H25.5C25.2099 73.9107 24.4423 75.1783 23.3266 76.0891C22.2109 76.9999 20.8153 77.4982 19.375 77.5C19.0435 77.5 18.7255 77.6317 18.4911 77.8661C18.2567 78.1005 18.125 78.4185 18.125 78.75C18.125 79.0815 18.2567 79.3995 18.4911 79.6339C18.7255 79.8683 19.0435 80 19.375 80H53.125C53.4565 80 53.7745 79.8683 54.0089 79.6339C54.2433 79.3995 54.375 79.0815 54.375 78.75C54.375 78.4185 54.2433 78.1005 54.0089 77.8661C53.7745 77.6317 53.4565 77.5 53.125 77.5C51.6847 77.4982 50.2891 76.9999 49.1734 76.0891C48.0577 75.1783 47.2901 73.9107 47 72.5H66.25C67.907 72.498 69.4956 71.8389 70.6672 70.6672C71.8389 69.4956 72.498 67.907 72.5 66.25V24.0625C72.498 22.4055 71.8389 20.8169 70.6672 19.6453C69.4956 18.4736 67.907 17.8145 66.25 17.8125ZM47 77.5H25.5C26.87 76.1566 27.7596 74.3994 28.0312 72.5H44.475C44.745 74.3987 45.6323 76.1557 47 77.5ZM70 66.25C70 67.2446 69.6049 68.1984 68.9016 68.9016C68.1984 69.6049 67.2446 70 66.25 70H6.25C5.25544 70 4.30161 69.6049 3.59835 68.9016C2.89509 68.1984 2.5 67.2446 2.5 66.25V65H70V66.25ZM70 62.5H2.5V24.0625C2.5 23.0679 2.89509 22.1141 3.59835 21.4108C4.30161 20.7076 5.25544 20.3125 6.25 20.3125H66.25C67.2446 20.3125 68.1984 20.7076 68.9016 21.4108C69.6049 22.1141 70 23.0679 70 24.0625V62.5Z"
      fill="#121E30"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M25.3983 36.057C25.8501 36.5791 25.7931 37.3685 25.2711 37.8202L18.2766 43.8732L25.2385 49.4963C25.7756 49.9301 25.8593 50.7171 25.4256 51.2542C24.9918 51.7912 24.2048 51.875 23.6677 51.4412L15.5427 44.8787C15.2547 44.6461 15.0846 44.2977 15.0783 43.9275C15.072 43.5573 15.2302 43.2033 15.5102 42.9611L23.6352 35.9298C24.1572 35.4781 24.9466 35.535 25.3983 36.057Z"
          fill="#121E30"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M47.0235 36.057C46.5718 36.5791 46.6288 37.3685 47.1508 37.8202L54.1453 43.8732L47.1833 49.4963C46.6463 49.9301 46.5625 50.7171 46.9963 51.2542C47.4301 51.7912 48.2171 51.875 48.7542 51.4412L56.8792 44.8787C57.1672 44.6461 57.3373 44.2977 57.3436 43.9275C57.3499 43.5573 57.1917 43.2033 56.9117 42.9611L48.7867 35.9298C48.2647 35.4781 47.4753 35.535 47.0235 36.057Z"
          fill="#121E30"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M43.2599 28.1629C43.8863 28.4532 44.1586 29.1964 43.8682 29.8227L30.4307 58.8071C30.1404 59.4334 29.3972 59.7057 28.7709 59.4154C28.1446 59.125 27.8723 58.3819 28.1626 57.7555L41.6001 28.7712C41.8905 28.1449 42.6336 27.8725 43.2599 28.1629Z"
          fill="#121E30"/>
  </Svg>

}

const Svg = styled.svg`
  @media (max-width: 600px){
    width: 54px;
    height: 54px;
  }
`;
