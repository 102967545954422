import "../common.scss"
import React from "react"
import { ScreenHello } from "../components/pageServices/ScreenHello"
import { SEO } from "../components/seo"
import { Services_PAGE_DESCRIPTION, Services_PAGE_TITLE } from "../constants/seo"
import { SectionAnalyticsAndDesign } from "../components/pageServices/SectionAnalyticsAndDesign"
import { SectionDevelopment } from "../components/pageServices/SectionDevelopment"
import { WriteToUs } from "../components/writeToUs/WriteToUs"
import { Footer } from "../components/footer/Footer"
import { SectionTesting } from "../components/pageServices/SectionTesting"
import { SectionOutsourcing } from "../components/pageServices/SectionOutsourcing"
import { Header } from "../components/newHeader/Header"

const Services = () => {
  return (
    <>
      <SEO title={Services_PAGE_TITLE} description={Services_PAGE_DESCRIPTION} />
      {/* <HeaderOld activeLink={"Services"} stickyHeader={true} /> */}
      <Header activeLink={"Services"} stickyHeader={true} />

      <main>
        <article>
          <ScreenHello />
          <SectionAnalyticsAndDesign />
          <SectionDevelopment />
          <SectionTesting />
          <SectionOutsourcing />
        </article>
      </main>

      <WriteToUs />
      <Footer />
    </>
  )
}

export default Services
