import styled from "astroturf"
import React from "react"
import imgAnalytic from "../../images/pageServices/imgAnalytic.png"
import imgDesign from "../../images/pageServices/imgDesign.png"
import imgDevelopment from "../../images/pageServices/imgDevelopment.png"
import imgOutsourcing from "../../images/pageServices/imgOutsourcing.png"
import imgTesting from "../../images/pageServices/imgTesting.png"
import { IconDevelopment } from "../../icon/pageServices/iconDevelopment"
import { IconAnalytics } from "../../icon/pageServices/iconAnalytics"
import { IconDesign } from "../../icon/pageServices/iconDesign"
import { IconTesting } from "../../icon/pageServices/iconTesting"
import { IconOutsourcing } from "../../icon/pageServices/iconOutsourcing"
import { useWindowSize } from "../../hooks/useWindowSize"

export const ScreenHello = () => {
  const { width } = useWindowSize()

  React.useEffect(() => {
    const elem = document.getElementById("img_1")
    if (elem) {
      elem.style.opacity = "1"
    }
  }, [])

  const onHoverLink = React.useCallback(value => {
    const elem = document.getElementById("img_" + value)
    if (elem) {
      elem.style.opacity = "1"
    }

    const elem1 = document.getElementById("img_1")
    if (elem1 && value !== 1) {
      elem1.style.opacity = "0"
    }
  }, [])

  const onOutLink = React.useCallback(value => {
    const elem = document.getElementById("img_" + value)
    if (elem) {
      elem.style.opacity = "0"
    }

    const elem1 = document.getElementById("img_1")
    if (elem1) {
      elem1.style.opacity = "1"
    }
  }, [])

  return (
    <Container className={"container"}>
      <Block>
        <div>
          <Title>Наши услуги</Title>
          <Description>
            Узнайте больше о наших услугах. Команда Dex решит вопросы со стратегией, дизайном и технологиями.
          </Description>
        </div>
        {width > 991 && (
          <ImageContainer>
            <img id={"img_2"} src={imgAnalytic} alt={"иконка Аналитика"} loading="lazy" />
            <img id={"img_3"} src={imgDesign} alt={"иконка Дизайн"} loading="lazy" />
            <img id={"img_1"} src={imgDevelopment} alt={"иконка Разарботка"} loading="lazy" />
            <img id={"img_4"} src={imgTesting} alt={"иконка Тестирование"} loading="lazy" />
            <img id={"img_5"} src={imgOutsourcing} alt={"иконка Аутсорс"} loading="lazy" />
          </ImageContainer>
        )}
      </Block>
      <ServicesContainer>
        <a
          onMouseOver={() => onHoverLink(2)}
          onFocus={() => onHoverLink(2)}
          onMouseOut={() => onOutLink(2)}
          onBlur={() => onOutLink(2)}
          href={"#analytics"}
          aria-label={"analytics"}
        >
          <IconAnalytics />
          Аналитика
        </a>
        <a
          onMouseOver={() => onHoverLink(3)}
          onFocus={() => onHoverLink(3)}
          onMouseOut={() => onOutLink(3)}
          onBlur={() => onOutLink(3)}
          href={"#analytics"}
          aria-label={"analytics"}
        >
          <IconDesign />
          Дизайн
        </a>
        <a
          onMouseOver={() => onHoverLink(1)}
          onFocus={() => onHoverLink(1)}
          onMouseOut={() => onOutLink(1)}
          onBlur={() => onOutLink(1)}
          href={"#development"}
          aria-label={"development"}
        >
          <IconDevelopment />
          Разработка
        </a>
        <a
          onMouseOver={() => onHoverLink(4)}
          onFocus={() => onHoverLink(4)}
          onMouseOut={() => onOutLink(4)}
          onBlur={() => onOutLink(4)}
          href={"#testing"}
          aria-label={"testing"}
        >
          <IconTesting />
          Тестирование
        </a>
        <a
          onMouseOver={() => onHoverLink(5)}
          onFocus={() => onHoverLink(5)}
          onMouseOut={() => onOutLink(5)}
          onBlur={() => onOutLink(5)}
          href={"#outsourcing"}
          aria-label={"outsourcing"}
        >
          <IconOutsourcing />
          Аутсорс
        </a>
      </ServicesContainer>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 220px;

  :global(#img_1) {
    position: absolute;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
  :global(#img_2) {
    position: absolute;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
  :global(#img_3) {
    position: absolute;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
  :global(#img_4) {
    position: absolute;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
  :global(#img_5) {
    position: absolute;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  @media (max-width: 600px) {
    padding-top: 80px;
  }
`

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 120px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding-bottom: 40px;
  }
`

const Title = styled.h1`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 64px;
  color: #121e30;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const Description = styled.h2`
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #121e30;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px) {
    display: none;
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #121e30;

    svg {
      margin-bottom: 20px;
    }

    @media (max-width: 991px) {
      margin: 0 15px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  @media (max-width: 991px) {
    overflow: auto;
    margin: 0 -30px;
  }
`
